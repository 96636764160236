/**
 * Connect the old chase api events via the Columbo API
 */
define(['app', 'columboService', 'siteObj'], function(app, columboService, siteObj) {

  const ColumboChase = function() {
    const module = {};

    const _init = function() {
      module.fastenPage();
    };

    const _fastenPage = function() {
      app.subscribe('pageLoad', module.sendPage);
      app.subscribe('pageLoad', () => {
        module.timingMetrics = module.getResourceTiming()
      });

      if (siteObj.features && !siteObj.features.combinePerformanceMetrics) {
        app.subscribe('pageLoad', module.sendPerfData);
        app.subscribe('hashchange', module.sendPerfData);

        if (siteObj.features && !siteObj.features.disablePerformanceMetrics) {
          if (siteObj.features && siteObj.features.usePageHideForWebVitals) {
            app.subscribe('pageHide', module.sendCombinedPerfMetrics);
          } else {
            app.subscribe('pageBeforeUnload', module.sendCombinedPerfMetrics);
          }
        }
      } else {
        app.subscribe('pageHide', module.sendCombinedPerfMetrics);
      }

      app.subscribe('basket-item-added', module.sendBasketItemAdded);
      app.subscribe('component-viewed-focus', module.sendComponentEvent);
      app.subscribe('component-viewed-blur', module.sendComponentEvent);
      app.subscribe('component-clicked', module.sendComponentEvent);
      app.subscribe('component-focused', module.sendComponentEvent);
      app.subscribe('component-hovered', module.sendComponentEvent);
      app.subscribe('columboChaseTracking', module.trackingListener);
    };

    const _sendPage = function() {
      module.columboService.send(siteObj.frontendTrackingUrl + '/t');
    };

    const _sendPerfData = function(data) {
      let metaCopy = Object.assign({}, data.meta);
      let reportingDataCopy = Object.assign({}, data.reporting);
      const messageData = Object.assign(metaCopy, reportingDataCopy, data.performance);
      module.columboService.send(siteObj.frontendTrackingUrl + '/e', 'PerfData', JSON.stringify(messageData));
    };

    const _sendCombinedPerfMetrics = function (data) {
      const eventData = {
        ref: document.referrer,
        event: 'PerfData'
      };

      let metaCopy = Object.assign({}, data.meta);
      let messageData;

      if (siteObj.features && siteObj.features.combinePerformanceMetrics) {
        let performanceCopy = Object.assign({}, data.performance);
        let experimentsCopy = Object.assign({}, data.experiments);
        messageData = Object.assign(metaCopy, performanceCopy, experimentsCopy, data.webVitals, eventData);
      } else {
        messageData = Object.assign(metaCopy, data.webVitals, eventData);
      }

      if(siteObj.features && siteObj.features.enableAbtasty) {
        messageData.abTastyTiming = module.timingMetrics;
      } else {
        messageData.abTastyTiming = [];
      }

      module.columboService.sendPerfValues(siteObj.frontendTrackingUrl + '/e', JSON.stringify(messageData));
    }

    const _sendBasketItemAdded = function(data) {
      const messageData = Object.assign(data.meta, data.basket);
      module.columboService.send(siteObj.frontendTrackingUrl + '/e', 'BasketItemAdded', JSON.stringify(messageData));
    };

    const _getResourceTiming = () => {
      const resourceEntry = []
      function perfObserver(list) {
        return list.getEntries().forEach((entry) => {
          if(entry.name.includes('https://try.abtasty.com/')) {
            resourceEntry.push({
              'name': entry.name,
              'timeToFirstByte': entry.responseStart - entry.startTime,
              'transfer': entry.responseEnd - entry.responseStart,
              'total': entry.responseEnd - entry.startTime,
              'encodedFileSize': (entry.transferSize/1024).toFixed(2),
            })
          }
        })
      }

      const observer = new PerformanceObserver(perfObserver);
      observer.observe({type: 'resource', buffered: true});
      return resourceEntry
    }

    const _sendComponentEvent = function(data) {
      module.columboService.sendFrontEndEvent(siteObj.frontendTrackingUrl + '/event', '', JSON.stringify(data));
    };

    const _trackingListener = function(data) {
      data = Object.assign({}, data);

      const subtype = data.args[0];
      const type = data.args[1];
      const attrKey = data.args[2];
      const attrValue = data.args[3];

      data.event = {
        subtype,
        type,
        is_interaction: false,
        contents: []
      };

      if (attrKey) {
        if (attrValue) {
          data.attributes = [{ name: attrKey, value: attrValue }];
        } else {
          data.event.contents = [{ html_element: attrKey }];
        }
      }

      module.columboService.sendFrontEndEvent(siteObj.frontendTrackingUrl + '/event', '', JSON.stringify(data));
    };

    module.init = _init;
    module.fastenPage = _fastenPage;
    module.sendPage = _sendPage;
    module.sendBasketItemAdded = _sendBasketItemAdded;
    module.sendComponentEvent = _sendComponentEvent;
    module.trackingListener = _trackingListener;
    module.sendCombinedPerfMetrics = _sendCombinedPerfMetrics;
    module.sendPerfData = _sendPerfData;
    module.getResourceTiming = _getResourceTiming;
    module.app = app;
    module.columboService = columboService;

    module.init();
    return module;
  };

  return ColumboChase();
});
